<template>
    <CardComponent :title="title" :icon="icon" :link="link">
        <section>
            <div class="sumData">
                <div>
                    <p>本月新入职员工</p>
                    <p>{{ employeeInfo.nearNewCount }}</p>
                </div>
                <div>
                    <p>在职员工</p>
                    <p>{{ employeeInfo.onJobCount }}</p>
                </div>
                <div>
                    <p>正式员工</p>
                    <p>{{ employeeInfo.formalCount }}</p>
                </div>
            </div>
            <gb-echart
                ref="distributorSale"
                config="employee-condition"
                class="echart-div"
            />

            <!-- <Empty text="开发中..." v-else /> -->
        </section>
    </CardComponent>
</template>

<script>
import CardComponent from '../components/card'
import GbEchart from '@/components/Echart'
import oaSvc from 'services/oa'
export default {
    name: '',
    components: { CardComponent, GbEchart },
    data() {
        return {
            title: '员工概况',
            icon: 'iconfont icon-yuangonggaikuang',
            link: '/oa/employee',
            employeeInfo: {}
        }
    },
    created() {
        this.initData()
    },
    methods: {
        async initData() {
            const { data = {} } = await oaSvc.employeeSummary({
                startTime: '2020-05-01',
                endTime: '2021-06-01'
            })
            this.employeeInfo = data
            this.dataHandle(this.employeeInfo)
        },
        dataHandle(data) {
            if (data) {
                const legendData = ['入职', '离职']
                let xAisData = []
                let seriesData = [
                    {
                        name: '入职',
                        type: 'line',
                        smooth: true,
                        data: [],
                        itemStyle: {
                            color: '#3595E1'
                        }
                    },
                    {
                        name: '离职',
                        type: 'line',
                        smooth: true,
                        data: [],
                        itemStyle: {
                            color: '#DF5A68'
                        }
                    }
                ]
                if (data.trendCounts.length > 0) {
                    data.trendCounts.forEach((item, index) => {
                        xAisData.push(
                            this.$moment(item.month).format('YYYY.MM')
                        )
                        seriesData[0].data.push(item.inCount)
                        seriesData[1].data.push(item.outCount)
                    })
                }
                this.$nextTick(() => {
                    const echart = this.$refs.distributorSale
                    echart.option.legend.data = legendData
                    echart.option.xAxis.data = xAisData
                    echart.option.series = seriesData
                    echart.refresh()
                })
            }
        }
    }
}
</script>
<style lang="less" scoped>
section {
    padding-top: 0.2rem;
    position: relative;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    .sumData {
        display: flex;
        // position: absolute;
        width: 100%;
        > div {
            display: flex;
            flex-direction: column;
            flex: 1;
            text-align: center;
            > p:nth-child(1) {
                font-size: 0.14rem;
                color: #a7b6c3;
                line-height: 0.24rem;
                font-weight: 400;
            }
            > p:nth-child(2) {
                font-size: 0.36rem;
                color: #54636f;
                font-weight: 500;
            }
        }
        margin-bottom: 50px;
    }
    .echart-div {
        flex: 1;
    }
}
</style>
